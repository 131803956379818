
import moment from "moment";
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import customers from "@/core/data/customers";
import { ICustomer } from "@/core/data/customers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRoute, useRouter } from "vue-router";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    ExportCustomerModal,
    VPagination,
    // AddCustomerModal,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const loginData = ref("");
    const userType = ref("");
    const store = useStore();
    const checkedCustomers = ref([]);
    const showTable = ref(false);
    const tableHeader = ref([
      // {
      //   key: "checkbox",
      //   sortable: false,
      // },
      {
        name: "User",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Phone",
        key: "number",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<ICustomer>>([]);
    const initCustomers = ref<Array<ICustomer>>([]);

    onMounted(() => {
      const data = localStorage.getItem("userdata");
      if (data) {
        var allData = JSON.parse("" + data + "");
        userType.value = allData;
        // console.log('allData',allData.type);
        if (allData.type == "admin") {
          setCurrentPageBreadcrumbs("User", []);
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          // console.log('tableData',tableData.value);
          getItem(1);
        } else {
          // console.log('tableData');
          router.push({ name: "dashbord" });
        }
      }
    });
    const limit = ref(10);
    const total_pages = ref(1);
    const getItem = (page) => {
      var request = {
        url: `/users?skip=${page}&limit=${limit.value}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          tableData.value = data.data;
          initCustomers.value.splice(
            0,
            tableData.value.length,
            ...tableData.value
          );
          total_pages.value = Math.ceil(data.total / limit.value);
          showTable.value = true;
          // console.log('datata',tableData.value);
        }
      });
    };

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          // for (let i = 0; i < tableData.value.length; i++) {
          //   if (tableData.value[i].id === id) {
          //     tableData.value.splice(i, 1);
          //   }
          // }
          // if (result.isConfirmed) {
          var request = {
            url: `user/${id}`,
          };
          store.dispatch(Actions.DELETE, request).then((data) => {
            showTable.value = false;
            Swal.fire("Deleted!", "User has been deleted.", "success");
            getItem(1);
          });
          // }
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<ICustomer> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const dateTime = (value) => {
      return moment(value).format("YYYY-MM-DD");
    };

    const updateHandler = (value) => {
      showTable.value = false;
      getItem(value);
    };

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      dateTime,
      showTable,
      getItem,
      loginData,
      userType,
      total_pages,
      updateHandler,
    };
  },
});
